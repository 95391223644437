export class MerchantModel {
    id: string = '';
    name: string = '';
    address: string = '';
    logo: string = '';
    active: boolean = false;
    location: {
        lat: number;
        lng: number;
    } = null;
    type: {
        id: string;
        name: string;
    };
    distance?: number;
    friendlyId?: string;
    logoId?: string;
    description?: string;
    isDelivering: boolean = false;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.address = json.address;
        this.active = json.active === '1';
        this.location = {
            lat: null,
            lng: null,
        };
        this.friendlyId = json.friendlyId;
        this.logoId = json.logoId;
        this.description = json.description;
        this.isDelivering = json.delivering === '1';

        try{
            this.logo = json.merchantLogoUrlTemplate.replace(
                '{merchantId}',
                this.logoId
            );
        }catch (e) {
            console.warn(e);
        }
        try {

            this.type = {
                id: json.type.id,
                name: json.type.name,
            };
            if(json.location){
                this.location = {
                    lat: Number(json.location.lat),
                    lng: Number(json.location.lng),
                };
            }

        } catch (e) {
            console.warn(e);
        }
    }
}
