import React, { FunctionComponent, useEffect, useState } from 'react'
import { Theme } from '@mobilly/core/src/assets/theme/Theme'
import GlobalStyle from '@mobilly/core/src/assets/global/GlobalStyle'
import styled, { ThemeProvider } from 'styled-components'
import MapBox from 'mobilly-labumi-map/src/components/map-box/MapBox'
import { MerchantModel } from 'mobilly-core/src/models/MerchantModel'
const axios = require('axios').default

export type Props = {}
export type Functions = {}
export type AllProps = Props & Functions

export const App: FunctionComponent<AllProps> = () => {
    const [merchants, setMerchants] = useState([])

    useEffect(() => {
        var formdata = new FormData()
        formdata.append('COMMAND', 'BENEFICIARY_MERCHANT_LIST')
        formdata.append('APPLICATION_KEY', 'HLjs6RiFPdIbCZwmGNBDX3FPFLoU3OId')
        formdata.append('RESPONSE_TYPE', 'JSON')
        formdata.append('APPLICATION_VERSION', '221106')
        axios
            .post('https://clients.mobilly.lv/api/', formdata, {
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded; charset=UTF-8',
                },
            })
            .then(response => {
                try {
                    const {
                        data: {
                            merchantLogoUrlTemplate,
                            merchants: {
                                data: { getBaseMerchants },
                            },
                        },
                    } = response

                    const merchants: MerchantModel[] = getBaseMerchants.list
                        .map(
                            l =>
                                new MerchantModel({
                                    ...l,
                                    merchantLogoUrlTemplate,
                                })
                        )
                        .filter(m => m.active)

                    // Some debug in Cēsis
                    const mm = merchants.map(m => {
                        if (m.id === '249017') {
                            return {
                                ...m,
                                location: {
                                    lat: 57.314574,
                                    lng: 25.290598,
                                },
                            }
                        }

                        if (m.id === '249099') {
                            return {
                                ...m,
                                location: {
                                    lat: 57.314038,
                                    lng: 25.296059,
                                },
                            }
                        }
                        return m
                    })

                    setMerchants(merchants)

                } catch (e) {
                    console.warn(e)
                }
            })
    }, [])

    return (
        <ThemeProvider theme={Theme}>
            <GlobalStyle />
            <MapBox
                style={{ width: '100vw', height: '100vh' }}
                markers={[
                    ...merchants.map(m => ({
                        title: m.name,
                        subtitle: m.address,
                        id: m.id,
                        icon: m.logo,
                        coordinates: {
                            lat: m.location.lat,
                            lng: m.location.lng,
                        },
                    })),
                ]}
                center={{
                    lat: 56.959016,
                    lng: 24.123513,
                }}
                accessToken={
                    'pk.eyJ1IjoibW9iaWxseXR4IiwiYSI6ImNrNTd4ZnBxZzA1dGMzbnFobGt0aXYxamEifQ.nwoMycbQs-5TQQIVh3gSRQ'
                }
                onPopupClick={() => {
                    console.log('onPopupClick')
                }}
            />
        </ThemeProvider>
    )
}

export default App
