import styled, {css} from 'styled-components';

export const Wrap = styled.div`
    height: 300px;
    position: relative;
    background: ${({theme})=>theme.colors.neutrals.n7};
    z-index: 1;
`;



