import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react'
import ReactDOM from 'react-dom'
const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js')
import 'mapbox-gl/dist/mapbox-gl.css'
import { Wrap } from './MapBox.css'
import Popup from './Popup'
import { sleep } from 'mobilly-core/src/utils/Utils'
import { Theme } from 'mobilly-core/src/assets/theme/Theme'
import BigLoader from "mobilly-core/src/components/preloaders/big-loader/BigLoader";

export type Marker = {
    title: string
    coordinates: { lat: number; lng: number }
    currentPosition?: boolean
    id?: string
    icon?: string
    subtitle?: string
}
export type Props = {
    center: { lat: number; lng: number }
    accessToken: string
    markers?: Marker[]
    style?: CSSProperties
    showGPSButton?: boolean
    defaultZoom?: number
}
export type Functions = {
    onPopupClick(id: any)
}
export type AllProps = Props & Functions

export const MapBox: FunctionComponent<AllProps> = ({
    style,
    markers,
    center,
    onPopupClick,
    accessToken,
    showGPSButton,
    defaultZoom,
}) => {
    const mapContainer = useRef()
    const [map, setMap] = useState(null)
    const popupContainer: HTMLElement = document.createElement('div')
    const [imagesLoaded, setImagesLoaded] = useState(false)

    console.log({ center, markers })

    const loadImage = async (
        properties: any
    ): Promise<{ successful: boolean; icon?: HTMLImageElement }> =>
        new Promise((resolve, reject) => {
            const iconLoader = new Image(150, 150)
            iconLoader.crossOrigin = 'Anonymous'
            iconLoader.onload = () => {
                resolve({ successful: true, icon: iconLoader })
            }

            iconLoader.onerror = error => {
                // 404
                console.warn('error loading image', {
                    error,
                    properties,
                })

                resolve({ successful: false })
            }

            if (!properties.icon) {
                return console.warn('missing icon', {
                    properties,
                })
            }
            iconLoader.src = properties.icon
        })

    useEffect(() => {
        mapboxgl.accessToken = accessToken
        if (!mapContainer.current || markers.length < 1) {
            return
        }

        const initMap = async () => {
            try {
                const geojson = {
                    type: 'FeatureCollection',
                    features: markers
                        .filter(m => !m.currentPosition)
                        .map(marker => {
                            const { coordinates, ...rest } = marker
                            return {
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [
                                        coordinates.lng,
                                        coordinates.lat,
                                    ],
                                },
                                properties: {
                                    ...rest,
                                },
                            }
                        }),
                }

                const map = new mapboxgl.Map({
                    container: mapContainer.current,
                    style:
                        'mapbox://styles/mobillytx/ck6uky6cl1q1o1ioboten9s23?optimize=true',
                    center,
                    zoom: defaultZoom || 10,
                })

                const addToMap = ({ title, icon }) => {
                    if (!map.hasImage(title)) {
                        map.addImage(title, icon)
                    } else {
                        console.warn('Map has such image already', {
                            title,
                        })
                    }
                }

                map.on('load', () => {
                    console.log('MAPBOX LOADED')
                    //load all icons
                    map.addImage('cluster', getClusterIcon())

                    const iconsLoader: Promise<void>[] = geojson.features.map(
                        ({ properties }) => {
                            return new Promise<void>(
                                async (resolve, reject) => {
                                    let { successful, icon } = await loadImage(
                                        properties
                                    )

                                    if (!successful) {
                                        const response = await loadImage({
                                            ...properties,
                                            icon: require('../../assets/images/marker.png'),
                                        })

                                        icon = response.icon
                                    }

                                    addToMap({ title: properties.title, icon })
                                    resolve()
                                }
                            )
                        }
                    )

                    Promise.all(iconsLoader)
                        .then()
                        .catch(e => console.warn('ignore images', { e })) // ignore images that are not loaded (404)
                        .finally(() => {
                            console.log('All images are loaded in Mapbox')

                            setImagesLoaded(true)
                            map.addSource('locations', {
                                type: 'geojson',
                                data: geojson,
                                cluster: true,
                                clusterRadius: 32,
                            })

                            //Adding layers
                            map.addLayer({
                                id: 'clusters',
                                type: 'symbol',
                                source: 'locations',
                                filter: ['has', 'point_count'],
                                layout: {
                                    'icon-image': 'cluster',
                                    'icon-allow-overlap': true,
                                },
                            })

                            map.addLayer({
                                id: 'cluster-count',
                                type: 'symbol',
                                source: 'locations',
                                filter: ['has', 'point_count'],
                                layout: {
                                    'text-field': '{point_count}',
                                    'text-font': ['Open Sans Bold'],
                                    'text-size': 12,
                                    'text-allow-overlap': true,
                                },
                                paint: {
                                    'text-color': '#ffffff',
                                },
                            })

                            map.on('mouseenter', 'clusters', () => {
                                map.getCanvas().style.cursor = 'pointer'
                            })
                            map.on('mouseleave', 'clusters', () => {
                                map.getCanvas().style.cursor = ''
                            })

                            console.log({ geojson })

                            geojson.features.forEach(({ properties }) => {
                                // Grouping layers by merchant title: a layer = merchant name/title, it's better to set merchant id as a layer id
                                // but since a merchant can have many restaurants/shops (addresses) we may have oblivion of images & layers
                                // also it's easy to filter locations by merchant title on the map without needing to refresh the whole map with new data
                                if (!map.getLayer(properties.title)) {
                                    map.addLayer({
                                        id: properties.title,
                                        type: 'symbol',
                                        source: 'locations',
                                        filter: [
                                            '==',
                                            ['get', 'title'],
                                            properties.title,
                                        ],
                                        layout: {
                                            'icon-image': properties.title,
                                            'icon-size': 0.3,
                                            'icon-allow-overlap': true,
                                            'text-allow-overlap': true,
                                        },
                                    })
                                }
                            })
                        })

                    //popup should overlap gps icon
                    popupContainer.style.zIndex = '2'
                    const popup = new mapboxgl.Marker(popupContainer)
                        .setLngLat([0, 0])
                        .addTo(map)

                    //Add listeners
                    map.on('click', 'clusters', e => {
                        const [feature] = map.queryRenderedFeatures(e.point, {
                            layers: ['clusters'],
                        })

                        console.log({ feature })
                        const clusterId = feature.properties.cluster_id
                        ;(map as any)
                            .getSource('locations')
                            .getClusterExpansionZoom(clusterId, (err, zoom) => {
                                if (err) {
                                    return
                                }
                                map.easeTo({
                                    center: (feature as any).geometry
                                        .coordinates,
                                    zoom: zoom + 1,
                                })
                            })
                    })

                    map.on('click', e => {
                        const [feature] = map.queryRenderedFeatures(e.point)

                        if (!feature || !feature.properties.title)
                            return setPopup(null)

                        const coordinates = (feature as any).geometry
                            .coordinates
                        popup.setLngLat(coordinates)
                        map.flyTo({ center: coordinates })
                        setPopup(feature.properties)
                    })

                    if (showGPSButton)
                        map.addControl(
                            new mapboxgl.GeolocateControl({
                                positionOptions: {
                                    enableHighAccuracy: true,
                                },
                                trackUserLocation: true,
                            }),
                            'top-left'
                        )

                    setMap(map)
                })
            } catch (e) {
                console.warn(e)
            }
        }

        console.log('MAPBOX MARKERS LENGTH', markers.length)

        initMap()

        return () => map && map.remove()
    }, [markers.length])

    const getClusterIcon = () => {
        const width = 24
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = width

        const context = canvas.getContext('2d')
        context.fillStyle = Theme.colors.partners.tet.base
        context.fillRect(0, 0, width, width)
        return context.getImageData(0, 0, width, width)
    }

    const setPopup = merchant => {
        ReactDOM.render(
            <Popup
                data={merchant}
                onPopupClick={() => onPopupClick(merchant && merchant.id)}
                onClose={() => ReactDOM.unmountComponentAtNode(popupContainer)}
            />,
            popupContainer
        )
    }

    return (
        <>
            {!imagesLoaded && <BigLoader/>}
            <Wrap style={{ ...style }} ref={mapContainer} />
        </>
    )
}

export default MapBox
