import { DefaultTheme } from 'styled-components';
export const Theme: DefaultTheme = {
    colors: {
        cars: {
            gray: '#B3B3B3',
            blue: '#68B3D8',
            black: '#292F36',
            white: '#FFFFFF',
            red: '#DD5757',
            brown: '#BF9066',
            yellow: '#FFE66D',
            green: '#50CC96'
        },
        violet: {
            base: '#5445D5',
            light: '#6F61EE',
            dark: '#4738CF'
        },
        automatic: {
            base: '#CCDB28'
        },
        error: {
            base: '#FF1432',
            light: '#FFDADC'
        },
        success: {
            base: '#00D74D',
            dark: '#04C549'
        },
        info: {
            base: '#38ADFE'
        },
        orange: {
            baseLegacy:'#F27D09',
            base: '#F06623',
            light: '#F4822E',
            dark: '#ED5728'
        },
        neutrals: {
            n1: '#000000',
            n2: '#262322',
            n3: '#403C39',
            n4: '#8C8581',
            n5: '#BFB8B4',
            n6: '#D9D0CC',
            n7: '#E6DFDC',
            n8: '#FAF5F2',
            n9: '#FFFFFF'
        },
        gradient: {
            base: `linear-gradient(180deg, #F4822E 0%, #F06623 51.47%, #ED5728 100%)`
        },
        transport: {
            train: {
                SKU: '#dbba1d',
                SIG: '#8dc641',
                AIZ: '#c09bc7',
                JEL: '#ed6347',
                TUK: '#00adef'
            },
            default: '#00D74D'
        },
        partners:{
            hagberg:{
                base:'#1ED760'
            },
            tet:{
                base:'#0060F5',
                light:'#01CBE0'
            }
        }
    },
    border: {
        radius: {
            s: '4px',
            m: '6px',
            l: '8px',
            xl: '24px'
        }
    },
    button: {
        height: {
            s: '30px',
            m: '56px'
        }
    }
};
