import styled from 'styled-components'
import React from 'react'
import { Svg } from 'mobilly-core/src/components/svg/Svg'

type IPopupProps = {
    data: any
    onClose: () => void
    onPopupClick: () => void
    footerText?: string
}

const Popup = ({
    data,
    onClose,
    onPopupClick,
    footerText,
    ...rest
}: IPopupProps) =>
    /*console.log('data',data)||*/ !data ? null : (
        <PopupWrapper {...rest}>
            <PopupCloseButton
                onMouseDown={onClose}
                dangerouslySetInnerHTML={{ __html: Svg.close(14) }}
            />
            <PopupContainer onMouseDown={onPopupClick}>
                <PopupBody>
                    <PopupTitle>{data.title}</PopupTitle>
                    <PopupDescription>{data.subtitle}</PopupDescription>
                </PopupBody>

                {footerText && (
                    <PopupFooter>
                        <FooterText>
                            {footerText}{' '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: Svg.rightArrow(10),
                                }}
                            />
                        </FooterText>
                    </PopupFooter>
                )}
            </PopupContainer>
        </PopupWrapper>
    )

const PopupWrapper = styled.div`
    width: 200px;
    transform: translateY(-50%) translateZ(0) scale(1, 1); // get rid of blurriness
    cursor: pointer;

    ::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        margin: auto;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #ffffff;
    }
`

const PopupCloseButton = styled.div.attrs({
    className: 'needsclick', // fastclick issue on iOS
})`
    position: absolute;
    top: 4px;
    right: 4px;
    width: 25px;
    height: 25px;
    text-align: right;
`

const PopupContainer = styled.div.attrs({
    className: 'needsclick',
})`
    background-color: #ffffff;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding: 8px;
`

const PopupBody = styled.div.attrs({
    className: 'needsclick',
})`
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    border-bottom: 1px rgba(41, 41, 41, 0.16) solid;
`

const PopupDescription = styled.div.attrs({
    className: 'needsclick',
})`
    overflow: hidden;
    font-weight: normal;
    font-size: 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    max-height: 32px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 4px;
`

const PopupTitle = styled.div.attrs({
    className: 'needsclick',
})`
    font-weight: bold;
    line-height: 1;
`

const PopupFooter = styled.div.attrs({
    className: 'needsclick',
})`
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
`

const FooterText = styled.div.attrs({
    className: 'needsclick',
})`
    font-weight: bold;
    font-size: 10px;
    opacity: 0.3;
`

export default Popup
