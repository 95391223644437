import React, { FunctionComponent } from "react";
import { Wrap } from "./BigLoader.css";
import { Svg } from "../../svg/Svg";
export enum Position {
  FIXED = "fixed",
  ABSOLUTE = "absolute",
  RELATIVE = "relative"
}
export type Props = {
  position?: Position;
};
export type Functions = {};

export type AllProps = Props & Functions;

export const BigLoader: FunctionComponent<AllProps> = ({position}) => (
  <Wrap dangerouslySetInnerHTML={{ __html: Svg.preloaderLogo() }} style={{position:position || Position.FIXED}}/>
);

export default BigLoader;
