import styled from "styled-components";

export const Wrap = styled.div`
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .svg-icon-logo-body{
    fill:${({theme})=>theme.colors.orange.light}
  }
  z-index: 1000;
`;
